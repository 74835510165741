import { DrupalNode } from "next-drupal";
import Link from "next/link";
import classNames from "classnames";
import { absoluteURL, formatDate } from "lib/utils";
import Image from "next/image";
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';

interface BlockFocusProps {
  node: DrupalNode;
}

export function BlockFocus({ node, ...props }: BlockFocusProps) {

  return (
    <article {...props} className="mx-auto px-6 ">




      

             {node.slice(0,1).map((focus) => (
    
      <Link href={focus.path.alias} key={focus.id} className="grid grid-cols-1 p-5 rounded-md items-center gap-x-16 gap-y-10 lg:grid-cols-2 hover:bg-gray-100">


      <div className="sm:flex lg:block">

   <h2 className="text-4xl font-bold tracking-tight text-gray-900">
               {focus.title}
              </h2>
  <p className="mt-4 text-gray-600">
                {focus.body.summary}
              </p>
         
</div>
                  <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg ">
            
               <Image
                          src={absoluteURL(focus.field_media_image.field_media_image.uri.url)}
                          alt={focus.field_media_image.field_media_image.resourceIdObjMeta.alt}
                          title={focus.field_media_image.field_media_image.resourceIdObjMeta.alt}
                          width={253}
                          height={390}
                className="object-cover object-center"
  style={{ objectFit: "contain" }}  // Maintient les proportions
                          loading="lazy"
                        />
            </div>


 
            </Link>

       
         ))}

     
         
        
          <div className="mt-16 grid grid-cols-1 gap-x-28 gap-y-10  lg:grid-cols-3">


      {node.slice(1,4).map((focus) => (


      <Link href={focus.path.alias} key={focus.id} className="sm:flex p-5 rounded-md lg:block hover:bg-gray-100">

  

 <div className="sm:flex-shrink-0">

            
                </div>
                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h3 className="text-2xl mb-6 font-bold text-gray-900">{focus.title}</h3>
                  <p className="mt-2 leading text-gray-600">{focus.body.summary}</p>
                </div>


   


           </Link>
         ))}


           
          </div>
     

    </article>
  );
}
