import { DrupalBlock } from "next-drupal"
import Link from "next/link"

interface Block3colsProps {
  block: DrupalBlock
}

export function Block3cols({ block }: Block3colsProps) {
  return (

<>
<div className="grid xs:grid-cols-1 sm:grid-cols-3 gap-4">

    {block.map((slider) => (
      <div key={slider.id} className="   bg-white p-3">

      {slider.title && (

      <div
                             dangerouslySetInnerHTML={{ __html: slider.title }}
                             className="titre text-xl "
                           />


      )}


      {slider.body && (

      <div
                             dangerouslySetInnerHTML={{ __html: slider.body?.value }}
                                className=" prose-sm pb-2 "
                           />


      )}

      {slider.field_lien_externe && (
      <div className="mb-3 ">
      <Link
        href={slider.field_lien_externe.uri.replace("entity:", "")}
        passHref
        >
      <span className="px-3 py-1 text-sm orange-500 text-white  transition-colors border-0 rounded-md bg-secondary hover:bg-white hover:text-black">
        {slider.field_lien_externe.title}
      </span>
      </Link>
      </div>
      )}


           </div>
         ))}
</div>
</>
  );
}
