import * as React from "react";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { drupal } from "lib/drupal";
import { GetStaticPropsContext, GetStaticPropsResult } from "next";
import { useTranslation } from "next-i18next";
import { QueryClient, QueryClientProvider, Hydrate } from "@tanstack/react-query";
import { Layout } from "components/layout";
import { BlockBannerhome } from "components/block--bannerhome";
import { BlockGt } from "components/block--grandstemoins";

import { BlockFocus } from "components/block--focus";
import { BlockFocus2 } from "components/block--focus2";
import { BlockPublications } from "components/block--publications";
import { BlockAgendaNew } from "components/block--agendanew";

import { Block3cols } from "components/block--3cols";
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { getGlobalElements } from "lib/get-global-elements";
import { getParams } from "lib/get-params";
import siteConfig from "site.config";

interface IndexPageProps {
  banner: any;
  slider: any;
  publication: any;
  menus: any;
  nodes: any;
  agenda: any;
  today: string;
  artseminaire: any;
}

export default function IndexPage({ banner, slider, artseminaire, seminarId, gt, publication, seminaires, menus, nodes, agenda }: IndexPageProps) {
  const { t } = useTranslation();

  return (
    <Layout meta={{ title: t("Accueil") }} menus={menus}>

      <div className="relative lavande rounded-md">
        <div className="inline-block h-[250px] sm:h-[400px]" />
        <div className="inset-0 z-2 flex items-center absolute py-6 px-6 xl:pl-18">
          <div className="mx-auto">
            <BlockBannerhome block={banner} />
          </div>
        </div>
      </div>
          <div className="max-w-screen-xl mx-auto py-6 md:py-14 lg:py-14 xl:py-14">
        <Block3cols block={slider} priority="low" />
        

      </div>
      <div className="max-w-screen-2xl px-6 mx-auto py-6 md:py-14 lg:py-14 xl:py-14">
            <div className="bg-white rounded-md p-6 ">

                <h2 className="inline-block  pb-0 md:pb-0 orange-title titre  pl-5 text-5xl">Agenda</h2>

        <BlockAgendaNew node={agenda} />

  <Link href="/agenda" 
              className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
passHref>Voir tout L&apos;agenda
                <ArrowLongRightIcon className="-mr-1 h-5 w-5 ml-3 inline-block text-gray-900" aria-hidden="true" />
</Link>



          
        </div>
      </div>
      <div className="max-w-screen-2xl mx-auto  px-6 py-6 md:py-14 lg:py-14 xl:py-14">
          
      <div className="bg-white rounded-md p-6 ">

          <h2 className="inline-block  orange-title titre text-3xl pl-5 md:text-5xl">Grands témoins</h2>
              <BlockGt node={gt} className="px-6" />

     

</div>
    
      </div>
      <div className="max-w-screen-2xl px-6 mx-auto  py-6 md:py-14 lg:py-14 xl:py-14">

            <div className="bg-white rounded-md p-6">

        <h2 className="inline-block  orange-title titre text-3xl pl-5 md:text-5xl">Séminaire</h2>

<BlockFocus2 node={artseminaire} seminaires={seminaires}/>

  <Link href="/seminaires" 
              className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
passHref>Voir les séminaires
                <ArrowLongRightIcon className="-mr-1 h-5 w-5 ml-3 inline-block text-gray-900" aria-hidden="true" />
</Link>


        
        </div>
      </div>
            <div className="  max-w-screen-2xl mx-auto container px-6 py-6 md:py-14 lg:py-14 xl:py-14">

      <div className="bg-white rounded-md p-6">
          <h2 className="inline-block pb-6 md:pb-10 orange-title titre text-3xl pl-5 md:text-5xl">FOCUS</h2>
                            <BlockFocus node={nodes} className="px-12"/>

      

        <Link href="/actualites" 
              className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
passHref>Voir tous les focus
                <ArrowLongRightIcon className="-mr-1 h-5 w-5 ml-3 inline-block text-gray-900" aria-hidden="true" />
</Link>

         
      </div>
      </div>
      <div className="max-w-screen-2xl px-6 mx-auto container py-6 md:py-14 lg:py-14 xl:py-14">
            <div className="bg-white rounded-md p-6">

        <h2 className="inline-block pb-6 md:pb-10 orange-title titre text-3xl pl-5 md:text-5xl">RÉFÉRENCES ET ÉTUDES</h2>
<BlockPublications node={publication}  className="px-12"/>


        <Link href="/publications" 
              className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
passHref>Voir toutes les publications
                <ArrowLongRightIcon className="-mr-1 h-5 w-5 ml-3 inline-block text-gray-900" aria-hidden="true" />
</Link>

    
        </div>
      </div>
    </Layout>
  );
}

export async function getStaticProps(context: GetStaticPropsContext): Promise<GetStaticPropsResult<IndexPageProps>> {
  const [banner] = await drupal.getResourceCollectionFromContext("block_content--basic", context, {
    params: getParams("block_content--basic").addFilter("info", "Heading-home").addPageLimit(1).getQueryObject(),
  });

  const slider = await drupal.getResourceCollectionFromContext("node--slider", context, {
    params: getParams("node--slider").addFields("node--slider", ["id", "title", "body", "field_tags","field_media_image","path", "field_lien_externe"]).addSort("field_ordre", "ASC").addPageLimit(3).getQueryObject(),
  });


  const publication = await drupal.getResourceCollectionFromContext("node--article", context, {
    params: getParams("node--article").addFields("node--article", ["id", "title", "field_media_image", "body", "path"]).addFilter("promote", "1").addFilter("field_categorie.id", "49efc8d7-2996-4289-8a82-d8be54279996").addInclude("field_media_image.field_media_image").addSort("created", "DESC").addPageLimit(6).getQueryObject(),
  });

  const today = new Date().toISOString();

  const agenda = await drupal.getResourceCollectionFromContext("node--article", context, {
    params: getParams("node--article").addInclude("field_paragraphs").addFields("node--article", ["id", "title", "field_categorie", "field_media_image", "field_paragraphs",  "field_date_de_l_agenda", "sticky","path"]).addFilter("field_date_de_l_agenda.value").addFilter("field_categorie.id", "112a43c1-e84d-4a06-ad88-1a87cb6979df").addSort("field_date_de_l_agenda.value", "DESC").getQueryObject(),
  });

  const nodes = await drupal.getResourceCollectionFromContext("node--article", context, {
    params: getParams("node--article").addFields("node--article", ["id", "title", "field_tags","field_media_image", "body", "path"]).addFilter("promote", "1").addFilter("field_categorie.id", "31254032-ea8d-4014-8a3c-c794d62568b5").addInclude("field_media_image.field_media_image").addSort("created", "DESC").addPageLimit(5).getQueryObject(),
  });

  const gt = await drupal.getResourceCollectionFromContext("node--article", context, {
    params: getParams("node--article").addFields("node--article", ["id", "title", "body", "path","field_media_image", "field_lien_externe", "field_tags",]).addFilter("promote", "1").addFilter("field_tags.id", "62cff0a1-7f7d-4eb6-889b-0c32d77bcad3").addInclude("field_media_image.field_media_image").addSort("created", "DESC").getQueryObject(),
  });


const seminaires = await drupal.getResourceCollectionFromContext<DrupalTaxonomyTerm[]>(
    "taxonomy_term--seminaires", // Le type de ressource que l'on récupère : des termes de taxonomie de type 'seminaires'
    context, // Contexte de la requête
    {
      params: {
        fields: {
          "taxonomy_term--seminaires": "id,path,name,weight,field_telecharger,description,field_media_image", // Champs à récupérer
        },
        include: "field_media_image.field_media_image, field_telecharger", // Champs supplémentaires à inclure
        sort: "-weight", // Tri par date de création, de la plus récente à la plus ancienne
        page: {
          limit: 1, // Limite à un seul résultat
        offset: 1, // Limite à un seul résultat

        },


      },
    }
  );

// Supposons que le premier séminaire retourné est celui que vous souhaitez utiliser
const seminarId = seminaires[0]?.id; // Récupérer l'ID du premier séminaire

const artseminaire = await drupal.getResourceCollectionFromContext("node--article", context, {
  params: getParams("node--article")
    .addFields("node--article", ["id", "title", "body", "field_tags", "field_date_de_l_agenda", "field_seminaire", "field_media_image", "path", "field_lien_externe"]) // Champs à récupérer
    // .addFilter("promote", "1") // Filtre pour récupérer uniquement les articles promus
    .addSort("created", "ASC") // Tri par date de création (ascendant)
    .addPageLimit(30) // Limite à 30 résultats
    .addFilter("field_seminaire.id", seminarId) // Filtrer par l'ID du terme de séminaire
    .getQueryObject(),
});



  return {
    props: {
      ...(await getGlobalElements(context)),
      slider,
      banner,
      publication,
      nodes,
      agenda,
      today,
      gt,
      seminarId,
      artseminaire,
      seminaires
    },
    revalidate: 5,
  };
}
