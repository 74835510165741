import { DrupalNode } from "next-drupal";
import Link from "next/link";
import classNames from "classnames";
import { absoluteURL, formatDate } from "lib/utils";
import Image from "next/image";
import { useState } from "react";
import {
  CalendarIcon,
  VideoCameraIcon,
  NewspaperIcon,
} from "@heroicons/react/20/solid";

interface BlockGtProps {
  node: DrupalNode;
}

export function BlockGt({ node, ...props }: BlockGtProps) {
  const cleanYouTubeURL = (url) => {
    // Remove any query parameters from the URL
    const baseUrl = url.split("?")[0];
    return baseUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/");
  };

  const extractVideoID = (url) => {
    // Get the video ID for the thumbnail image
    return url.split("?")[0].split("/").pop();
  };

  const initialVideo =
    node.length > 0 && node[0].field_lien_externe
      ? cleanYouTubeURL(node[0].field_lien_externe.uri)
      : "";
  const [selectedVideo, setSelectedVideo] = useState(initialVideo);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleThumbnailClick = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setIsVideoLoaded(false);
  };

  return (
    <article
      {...props}
      className="px-6 mt-6 grid lg:grid-cols-2 gap-6 bg-white h-full"
    >
      {/* Colonne gauche : Vidéo */}
      <div className="w-full">
        {isVideoLoaded ? (
          <iframe
            width="100%"
            height="415"
            src={selectedVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <div className="cursor-pointer" onClick={handleThumbnailClick}>
            <div className="relative w-full h-[434px] overflow-hidden">
              <Image
                src={`https://img.youtube.com/vi/${extractVideoID(
                  selectedVideo
                )}/maxresdefault.jpg`}
                alt="Video thumbnail"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
              {/* Play Icon Overlay */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="play-icon bg-black bg-opacity-50 rounded-full p-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    viewBox="0 0 24 24"
                    className="h-10 w-10"
                  >
                    <path d="M3 22v-20l18 10-18 10z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Colonne droite : Liste des vidéos */}
      <div
        className="w-full flex flex-col h-full overflow-y-auto max-h-[434px]"
        style={{ height: "100%" }}
      >
        <ol className="divide-y divide-gray-100 leading-6">
          {node.map((grandt) => {
            const videoUrl = cleanYouTubeURL(grandt.field_lien_externe.uri);
            const isSelected = videoUrl === selectedVideo;

            return (
              <li
                key={grandt.id}
                className="relative flex space-x-6 py-6 hover:bg-gray-100"
              >
                {grandt.field_lien_externe && (
                  <span
                    onClick={() => handleVideoClick(videoUrl)}
                    className={classNames(
                      "cursor-pointer w-full px-3 py-1 transition-colors border-0 rounded-md",
                      {
                        "bg-gray-100 cursor-default": isSelected,
                        "hover:bg-gray-100 hover:text-black": !isSelected,
                      }
                    )}
                  >
                    <div className="flex gap-x-4 w-full">
                      <Image
                        src={absoluteURL(
                          grandt.field_media_image.field_media_image.uri.url
                        )}
                        alt={
                          grandt.field_media_image.field_media_image.uri.url ||
                          "Image"
                        }
                        width={100}
                        height={100}
                        style={{ objectFit: "contain" }}
                        className="h-14 w-14 flex-none"
                        loading="lazy"
                      />
                      <div className="flex-auto overflow-hidden w-full">
                        <h3 className="font-bold leading-6 text-gray-900">
                          {grandt.title}
                        </h3>
                        <div className="mt-1 text-gray-600 line-clamp-2">
                          {grandt.body?.summary}
                        </div>
                        <dl className="mt-2 flex flex-col text-gray-600 xl:flex-row">
                          <div className="flex items-start space-x-3">
                            <dt className="mt-0.5">
                              <span className="sr-only">Voir la vidéo</span>
                              <VideoCameraIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd>
                              <span
                                className="rounded-md border border-transparent bg-white px-4 mt-3 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleVideoClick(videoUrl)}
                              >
                                Voir la vidéo
                              </span>
                            </dd>
                          </div>
                          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                            <dt className="mt-0.5">
                              <span className="sr-only">Localisation</span>
                              <NewspaperIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd>
                              <Link
                                href={grandt.path.alias}
                                className="rounded-md border border-transparent bg-white px-4 mt-3 hover:bg-gray-100"
                                passHref
                              >
                                Lire l&apos;article
                              </Link>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </article>
  );
}

