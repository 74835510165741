import { DrupalBlock } from "next-drupal"
import Link from "next/link"

interface BlockBannerhomeProps {
  block: DrupalBlock
}

export function BlockBannerhome({ block }: BlockBannerhomeProps) {

  const truncate = (input: string) =>
      input?.length > 70 ? `${input.substring(0, 70)}...` : input;
  return (
<>
<h1 className="invisible">{truncate(block.body.value)}</h1>
          {block.body && (

            <div
                                     dangerouslySetInnerHTML={{ __html: block.body?.value }}
                                     className="sm:text-3xl mb-5 lg:text-5xl  xs:leading-10  leading-loose md:leading-loose lg:leading-loose xl:leading-loose max-w-screen-xl     titre    border-l-4 pl-5 border-orange-500   mt-4 "
                                   />


          )}

          {block.field_content_link && (
            <Link

              href={block.field_content_link.uri.replace("entity:", "")}
              passHref
              className="px-3 py-1 text-sm orange-500 text-white  transition-colors border-2 rounded-md bg-secondary hover:bg-white hover:text-black border-secondary"
              >
                {block.field_content_link.title}

            </Link>
          )}
      </>
  );
}
