import { DrupalNode } from "next-drupal";
import Link from "next/link";
import classNames from "classnames";
import { absoluteURL, formatDate } from "lib/utils";
import Image from "next/image";
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';

interface BlockPublicationsProps {
  node: DrupalNode;
}

export function BlockPublications({ node, ...props }: BlockPublicationsProps) {

  return (
    <article {...props} className="block-agenda bg-gray-50">




      <div className="mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
      <div className="mx-auto  px-4 lg:max-w-none">
                
  <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">

             {node.map((focus) => (
    
 <Link href={focus.path.alias} key={focus.id} className="grid grid-cols-1 rounded-md p-5 items-center gap-x-16 gap-y-10 lg:grid-cols-2 hover:bg-white" >


      <div className="sm:flex lg:block">

   <h2 className="text-4xl font-bold tracking-tight text-gray-900">
               {focus.title}
              </h2>
  <p className="mt-4 text-gray-600">
                {focus.body.summary}
              </p>
</div>
                  <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
            
               <Image
                          src={absoluteURL(focus.field_media_image.field_media_image.uri.url)}
                          alt={focus.field_media_image.field_media_image.resourceIdObjMeta.alt}
                          title={focus.field_media_image.field_media_image.resourceIdObjMeta.alt}
                          width={253}
                          height={390}
                className="object-cover object-center"
  style={{ objectFit: "contain" }}  // Maintient les proportions
                          loading="lazy"
                        />
            </div>


         
</Link>
         

       
         ))}
</div>
     
    



        </div>
      </div>
    



    </article>
  );
}
